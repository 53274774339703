'use client';

import styles from './ButtonJoinNow.module.scss';

const basePath = process.env.AUTH0_BASE_URL;

export const ButtonJoinNow = () => {
	const handleClickJoinNow = () => {
		const redirectURL = `${basePath}/api/auth/signup`;
		window.location.href = redirectURL;
	};

	return (
		<button tabIndex={0} className={styles['join-now']} data-testid='join-now-button' onClick={handleClickJoinNow}>
			Join Now
		</button>
	);
};

export default ButtonJoinNow;
